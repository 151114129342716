import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";
import FormComponent from "../components/ui/FormComponent";
import TextComponent from "../components/ui/TextComponent";
import { CardPriceComponent } from "../components/ui/CardPriceComponent";

export default function WebsiteRevisionPage({ data }) {
  return (
    <Layout
      title="Доработка сайтов"
      description="Доработка сайтов цена. Заказать доработку сайта"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Доработка сайтов</TitleComponent>
        {data.content.nodes.map((item) => (
          <div key={item.id}>
            <TextComponent>{item.name}</TextComponent>
          </div>
        ))}
      </MarginComponent>
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Доработка сайтов цена</TitleComponent>
        <MarginComponent small="sm:mt-5" margin="mt-4">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
            {data.price.nodes.map((item) => (
              <CardPriceComponent price={item} show />
            ))}
          </div>
        </MarginComponent>
      </MarginComponent>
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Заказать доработку сайта</TitleComponent>
        <FormComponent />
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    content: allSanityContent(
      filter: { slug: { current: { eq: "dorabotka-saytov" } } }
    ) {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
    list: allSanityList(
      filter: { cat: { eq: "dorabotka-saytov" } }
      sort: { order: ASC, fields: _createdAt }
    ) {
      nodes {
        id
        name
      }
    }
    price: allSanityPrice(
      filter: { cat: { elemMatch: { name: { eq: "improvement" } } } }
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        id
        name
        cost
        days
        slug {
          current
        }
      }
    }
  }
`;
